import Tooltip from "@material-ui/core/Tooltip";
import { useI18nFormatDateString } from "@utils/hooks/use-i18n-format-date-string";
import { iconManager } from "@utils/icon-manager";
import clsx from "clsx";
import React from "react";
import { useIntl } from "react-intl";

import * as S from "./TooltipTimeList.module.scss";

const TooltipTimeList = ({ updated, published, tooltip = false, type }) => {
  const intl = useIntl();
  const formatDate = useI18nFormatDateString("PP");

  const renderTimeList = () => {
    return (
      <ul
        className={clsx({
          [S.timeList]: true,
          [S.horizontal]: !tooltip,
          [S.customized]: type === "course",
        })}>
        <li className={S.updatedTime}>
          <div className={S.title}>
            {type === "course" || tooltip ? null : (
              <span className={S.icon}>{iconManager("Update")}</span>
            )}
            <span>
              {intl.formatMessage({
                id: "common.tooltip.updated",
              })}
            </span>
          </div>
          <div>{formatDate(updated)}</div>
        </li>
        <li>
          <div className={S.title}>
            {intl.formatMessage({
              id: "common.tooltip.published",
            })}
          </div>
          <div>{formatDate(published)}</div>
        </li>
      </ul>
    );
  };

  return tooltip ? (
    <Tooltip title={renderTimeList()} placement="bottom-start">
      <li className={S.default_display}>
        <i>{iconManager("Update")}</i>
        <span>{formatDate(updated)}</span>
      </li>
    </Tooltip>
  ) : (
    renderTimeList()
  );
};

export default TooltipTimeList;
