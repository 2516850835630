import Avatar from "@components/Avatar";
import TooltipTimeList from "@components/TooltipTimeList";
import { getCurrencyText } from "@constants/price-setting";
import ViewAllTooltip from "@page-components/ViewAllTooltip";
import { useFormatDuration } from "@utils/hooks/use-format-duration";
import { useI18nFormatDateString } from "@utils/hooks/use-i18n-format-date-string";
import { iconManager } from "@utils/icon-manager";
import { CustomCourseCover } from "@utils/image-utils";
import { numberAbbr } from "@utils/number-utils";
import { getUserName } from "@utils/parse-utils";
import clsx from "clsx";
import { useRouter } from "next/router";
import React, { Fragment } from "react";
import { useIntl } from "react-intl";

import * as S from "./Card.module.scss";

const Card = ({
  cardData,
  isCampaign,
  showPrice = false,
  horizontal = false,
}) => {
  const intl = useIntl();
  const router = useRouter();
  const formatDate = useI18nFormatDateString("PP");
  const formatDateTime = useI18nFormatDateString("PP HH:mm:ss");
  const formatDuration = useFormatDuration();
  const directToCardPreview = (event, id) => {
    event.preventDefault();
    router.push(`/${isCampaign ? "campaign" : "course"}/preview/${id}`);
  };
  return (
    <Fragment>
      {cardData.map((item, index) => {
        const {
          coverImg,
          duration,
          enrollCount,
          id,
          price,
          provider,
          title,
          viewCount,
          courseTotal,
          publishedTime,
          initialPublishedTime,
        } = item;
        const { avatar = null } = provider || {};
        const isUpdate =
          formatDateTime(publishedTime) > formatDateTime(initialPublishedTime);
        return (
          <a
            className={clsx({
              [S.cardItem]: true,
              [S.horizontal]: horizontal,
            })}
            key={index}
            onClick={(event) => directToCardPreview(event, id)}
            href={`/${isCampaign ? "campaign" : "course"}/preview/${id}`}>
            <div className={S.imgBox} test-id="img">
              <CustomCourseCover image={coverImg} />

              <div className={S.videoTime} test-id="videoTime">
                {formatDuration(duration)}
              </div>
              <div className={S.cardTypeBox}>
                {courseTotal ? (
                  <div className={S.cardTypeBoxInner}>
                    <div className={S.TypeTitle}>
                      {intl.formatMessage({
                        id: "common.itemType.bundleCourse",
                      })}
                    </div>
                    <div className={S.iconWithText}>
                      <div className={S.iconBox}>{iconManager("course")}</div>
                      <div className={S.subText}>
                        {intl.formatMessage(
                          {
                            id: "common.itemType.bundleCourseByCount",
                          },
                          { count: courseTotal },
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className={S.cardContent}>
              <div className={S.cardContentInner}>
                <ViewAllTooltip>
                  <div className={S.cardTitle} test-id={`cardTitle_${title}`}>
                    {title}
                  </div>
                </ViewAllTooltip>
                <div className={S.priceWrapper}>
                  <div className={S.avatarBox}>
                    <Avatar
                      {...provider}
                      width={24}
                      src={avatar}
                      isPointer={false}
                      test-id="avatar"
                    />
                    <div className={S.nameBox} test-id="name">
                      {getUserName(provider)}
                    </div>
                  </div>
                  {showPrice && (
                    <div className={S.priceText} test-id="price">
                      {getCurrencyText({
                        ...price,
                        intl,
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className={S.subInfo}>
                <div className={S.subInfoInner}>
                  <div className={S.timeZone}>
                    {isUpdate ? (
                      <TooltipTimeList
                        updated={publishedTime}
                        published={initialPublishedTime}
                        tooltip={!horizontal}
                      />
                    ) : (
                      <span>{formatDate(initialPublishedTime)}</span>
                    )}
                  </div>
                  <div className={S.icon_wrapper}>
                    <div className={S.viewer}>
                      <div className={S.iconBox}>{iconManager("Views")}</div>
                      <div
                        className={S.quantity}
                        test-id="viewCount"
                        title={viewCount}>
                        {numberAbbr(viewCount)}
                      </div>
                    </div>
                    <div className={S.enrollmentsBox}>
                      <div className={S.iconBox}>{iconManager("Enroll")}</div>
                      <div
                        className={S.quantity}
                        test-id="enrollment"
                        title={enrollCount}>
                        {numberAbbr(enrollCount)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        );
      })}
    </Fragment>
  );
};

export default Card;
