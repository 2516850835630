import Icon from "@material-ui/core/Icon";
import * as S from "@styles/components/_avatar.module.scss";
import { getUserName } from "@utils/parse-utils";
import clsx from "clsx";
import React from "react";

const generateRoundSize = (width) => {
  let avatarSize;
  switch (width) {
    case 24:
      avatarSize = S.avatar24;
      break;
    case 40:
      avatarSize = S.avatar40;
      break;
    case 50:
      avatarSize = S.avatar50;
      break;
    case 64:
      avatarSize = S.avatar64;
      break;
    case 104:
      avatarSize = S.avatar104;
      break;
    case 30:
    default:
      avatarSize = S.avatar30;
      break;
  }
  return avatarSize;
};

const Avatar = (props) => {
  const { src, onClick, width = 30, isPointer, camera, open } = props;
  const userName = getUserName(props);
  if (src !== undefined && src !== "null" && src !== null && src) {
    return (
      <div
        className={clsx({
          [generateRoundSize(width)]: true,
          [S.clickActive]: isPointer,
        })}
        onClick={onClick}
        style={{ backgroundImage: `url(${src})` }}>
        {camera ? (
          <div
            className={clsx({
              [S.cameraIconBox]: true,
              [S.active]: open,
            })}>
            <Icon>camera_alt</Icon>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div
      className={clsx({
        [generateRoundSize(width)]: true,
        [S.clickActive]: isPointer,
      })}
      onClick={onClick}
      id="avatar">
      <div id="avatar-outline" className={S.avatarText}>
        {userName ? userName.slice(0, 1) : ""}
        {camera ? (
          <div
            className={clsx({
              [S.cameraIconBox]: true,
              [S.active]: open,
            })}
            id="avatar-round">
            <Icon>camera_alt</Icon>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Avatar;
