import { filter } from "ramda";
import { useEffect, useState } from "react";

import { getCentralInfo } from "../apiServices/central";

const parseBoolean = (value) => {
  if (typeof value === "string") {
    return value === "true";
  }
  if (typeof value === "boolean") {
    return value;
  }
  return false;
};

export const useCentralInfo = ({ isPublic = false, keys = [] }) => {
  const [showPriceWeb, setShowPriceWeb] = useState(false);
  const [showPriceApp, setShowPriceApp] = useState(false);
  const [skipEnroll, setSkipEnroll] = useState(false);
  const [enableDiscussion, setEnableDiscussion] = useState(false);
  const [maskBlur, setMaskBlur] = useState(false);

  useEffect(() => {
    const getCentralInfoSource = async () => {
      try {
        const { data, error } = await getCentralInfo({ isPublic, keys });
        if (error) {
          throw error;
        }
        const isShowPriceWebKey = (n) => n.key === "showPriceWeb";
        const isShowPriceAppKey = (n) => n.key === "showPriceApp";
        const isSkipEnrollKey = (n) => n.key === "skipEnroll";
        const isEnableDiscussionKey = (n) => n.key === "forumEnabled";
        const isMaskBlurKey = (n) => n.key === "maskBlur";
        setShowPriceWeb(
          parseBoolean(filter(isShowPriceWebKey, data)[0]?.value),
        );
        setShowPriceApp(
          parseBoolean(filter(isShowPriceAppKey, data)[0]?.value),
        );
        setSkipEnroll(parseBoolean(filter(isSkipEnrollKey, data)[0]?.value));
        setEnableDiscussion(
          parseBoolean(filter(isEnableDiscussionKey, data)[0]?.value),
        );
        setMaskBlur(parseBoolean(filter(isMaskBlurKey, data)[0]?.value));
      } catch (error) {
        console.error("getCentralInfoSource", error);
      }
    };
    getCentralInfoSource();
  }, []);
  return {
    showPriceWeb,
    showPriceApp,
    skipEnroll,
    enableDiscussion,
    maskBlur,
  };
};
