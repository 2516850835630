import format from "date-fns/format";
import parse from "date-fns/parse";
import parseJSON from "date-fns/parseJSON";

import { useFnsLanguage } from "./use-fns-language";

export const useI18nFormatDateString = (formatString = "PP") => {
  const dateLocale = useFnsLanguage();
  return (dateString, dateFormat = formatString) => {
    try {
      if (!dateString) return "";
      if (typeof dateString === "object") {
        return format(dateString, dateFormat, {
          locale: dateLocale,
        });
      }
      if (typeof dateString === "string") {
        if (dateString.indexOf("GMT") !== -1) {
          // Thu Sep 08 09:02:53 GMT 2022
          return format(parseJSON(new Date(dateString)), dateFormat, {
            locale: dateLocale,
          });
        }
        if (dateString.indexOf(":") !== -1) {
          // include hh:mm:ss
          return format(parseJSON(dateString), dateFormat, {
            locale: dateLocale,
          });
        }
        // only yyyy-MM-dd
        return format(parse(dateString, "yyyy-MM-dd", new Date()), dateFormat, {
          locale: dateLocale,
        });
      }
    } catch (error) {
      console.error("parseJSON and parse failed dateString", dateString);
    }
    return dateString;
  };
};
