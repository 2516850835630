import clientAPI, { clearCache } from "@utils/axios/client-api";
import qs from "querystringify";

const putCentralInfo = (postData) => {
  const { key, value } = postData;
  /**
   * DKAM-5604
   * Clear cache when central info set up
   *  */
  clearCache();

  return clientAPI
    .put(`/api/v1/central/console/setting?key=${key}&value=${value}`)
    .then((res) => res.data);
};
const getCentralInfo = ({ isPublic = false, keys = [] }) => {
  const query = qs.stringify({
    keys,
  });
  const url = isPublic
    ? `/api/v1/central/setting?${query}`
    : `/api/v1/central/console/setting?${query}`;
  return clientAPI.get(url).then((response) => response.data);
};
export { getCentralInfo, putCentralInfo };
